.type-system-sans {
  $base-border-radius: 3px !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #a4a5a6 !default;
  $light-gray: #d9dadb !default;
  $medium-screen: em(640) !default;
  $base-font-color: $dark-gray !default;
  $sans-serif: 'PT Sans', sans-serif;
  $sans-serif-2: 'Titillium Web', sans-serif;

  //@include clearfix;
  font-family: $sans-serif;
  text-align: left;
  
  h1, h2, h3, p {
    margin: 0;
  }

  hr {
    border-bottom: 1px solid $light-gray;
    border-left: none;
    border-right: none;
    border-top: none;
    margin: $base-spacing 0;
  }

  p {
    color: $dark-gray;
    line-height: $base-line-height;
  }

  a {
    color: $action-color;
    text-decoration: none;
  }

  .type {
    border-bottom: 1px solid;
    display: inline-block;
    font-family: $sans-serif-2;
    font-size: 1em;
    font-weight: 900;
    letter-spacing: 1px;
    margin-bottom: 2em;
    padding: 0.1em 0;
    text-align: left;
    text-transform: uppercase;
  }

  h1 {
    font-family: $sans-serif-2;
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 0.6em;

    @include media($medium-screen) {
      font-size: 2.4em;
    }
  }

  h2 {
    font-family: $sans-serif;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 1.2em;

    @include media($medium-screen) {
      font-size: 1.4em;
    }
  }

  code {
    background: #F7F7F7;
    border-radius: $base-border-radius * 1.5;
    border: 1px solid #E0E0E0;
    font-family: monaco;
    font-size: 0.65em;
    font-style: normal;
    padding: 0.1em 0.4em;
    white-space: nowrap;
  }

  h3 {
   font-family: $sans-serif-2;
   font-size: 1em;
   font-weight: 700;
   line-height: 1.4em;
   margin-bottom: 0.5em; 
   text-transform: uppercase;
  }

  p.date {
    color: transparentize($base-font-color, 0.6);
    font-family: $sans-serif;
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }

  p {
    font-family: $sans-serif;
    font-weight: 300;
    letter-spacing: 1;
    margin-bottom: 1.5em;

    span {
      font-family: $sans-serif;
      font-size: 0.8em;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  /*
  hr {
    width: 3em;
  }
  */

  a.read-more {
    display: inline-block;
    font-family: $sans-serif;
    font-size: 0.8em;
    font-weight: 700;
    margin-left: 0.2em;
    position: relative;
    text-transform: uppercase;

    span {
      font-size: 1.7em;
      position: absolute;
      right: -10px;
      top: -2px;
    }
  }

  p.author {
    font-family: $sans-serif;
    font-style: italic;
  }
}
