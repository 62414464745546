@import "../neat/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

// Neat Overrides
$column: 90px;
$gutter: 30px;
$grid-columns: 12;
$max-width: 1200px;

// Neat Breakpoints
$medium-screen: 600px;
$large-screen: 900px;

$medium-screen-up: new-breakpoint(min-width $medium-screen 4);
$large-screen-up: new-breakpoint(min-width $large-screen 8);
