@import 'bourbon/bourbon';
@import 'base/base';
@import 'neat/neat';
@import 'type-system-sans';
@import 'base/variables';

$light-gray: #d9dadb;
$primary-color: #a4a5a6;
$red-color: #d32e4d;
$mobile: new-breakpoint(max-width 500px 4);

/* Layout */
.center {
  text-align: center;
  margin: 0 auto;
  width: auto;
  display: inherit; }

* {
  box-sizing: border-box; }

.primary {
  width: 100%; }

main.primary {
  max-width: 980px;
  margin: 0 auto;
  @include outer-container; }

main > section {
  &:first-child {
    padding: 20px 0 0 0; }
  &.half {
    @include span-columns(6);
    @include media($mobile) {
      width: 100%; } } }

main > hr {
  @include fill-parent(); }
/* Typography */
body {
  color: $primary-color; }

h1, h2, h3, h4, h5, h6 {
  color: $red-color; }

h1 {
  font-size: 2.4em; }

a {
  transition: 0.5s;
  &:hover {
    color: $red-color; } }


/* Colors */

/* Quick Override */
.type-system-sans {
  p {
    color: $primary-color; } }

main > section h2.type:first-child {
  font-size: 1.6em; }

/* Modules */
header.primary {
  @extend .center;
  border-bottom: 2px $light-gray solid;
  a {
    color: $primary-color;
    text-decoration: none;
    transition: 0.5s;
    &:hover {
      color: $red-color !important; } } }


footer.primary {
  margin-top: 20px;
  padding: 20px 0;
  border-top: 2px $light-gray solid; }
